import './Footer.css'

const Footer = () => (
  <footer className='footer'>
    <a
      href='https://www.youtube.com/watch?v=UNuogmk7oEA'
      className='link footer__link'
    >
      Thank you for Visiting! (Click Me for a Surprise!)
    </a>
  </footer>
)

export default Footer
