const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://lovep.dev',
  title: 'LP.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Love Patel',
  role: 'Computer Science Student',
  description:
      "Greetings and welcome to my website! Here, you'll have the opportunity to get to know me better. A leisure activity I truly enjoy is playing video games during my free moments. I have an insatiable curiosity for acquiring new skills and hobbies. Feel free to explore further by scrolling down or clicking on the top-right corner. Your visit is greatly appreciated!",
  resume: 'https://example.com',
  social: {
    linkedin: 'https://linkedin.com/in/lvp5345',
    github: 'https://github.com/Love-P',
  },
}





const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Gym Management System',
    description: [
      "Developed an Entity Relationship Diagram (ERD) and Data Dictionary to effectively plan the creation of a SQL database.",
      "Created a secure and efficient web application that includes a login system for improved access control for both users and admins.",
      "Assigned varying levels of access and responsibilities to different roles within the application, ensuring proper task management."
    ],
    stack: ['Oracle Apex','SQL'],
    sourceCode: 'https://github.com',
  },
  {
    name: 'World Language Lookup and Translator',
    description: [
      "Constructed Excel Spreadsheets consisting data for countries and languages spoken in that desired country.",
      "Engineered command line tool for World Language lookup by converting the excel sheets into lists.",
      "Leveraged the Google Translate API to skillfully incorporate a Translate Feature, enabling real‑time word or sentence translation via the command‑line."
    ],
    stack: ['Python','Vim','Excel'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: 'Bank Application Simulator',
    description: [
      "Conceptualized a command line tool for a simple banking application.",
      "Developed an interface to allow multiple types of users, through a login(Customer, Employee, IT).",
      "Customer and Employee data is stored and retrieved from a text file. Accessed using different actions and roles."
    ],
    stack: ['C++','xCode'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
]


const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML',
  'CSS',
  'JavaScript',
  'Python',
  'C/C++',
  'JupyterLab/JupyterNotebook',
  'Shell',
  'SQL',
  'ReactJS',
  'Git/Github',
  'Docker',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'lvp5345@gmail.com',
}

export { header, about, projects, skills, contact }